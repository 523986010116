import React from "react";
import { format } from "date-fns";
import {
  Box,
  Stack,
  Typography,
  LinearProgress,
  Avatar,
  Tooltip,
} from "@mui/material";

import { DataGrid } from "@mui/x-data-grid";
import {
  formatCurrency,
  formatWithoutTimezone,
  rowsPerPageOptions,
  tableCellStyle,
} from "../../../../utils/common";
import createAvatar from "../../../../utils/createAvatar";
import { useDispatch, useSelector } from "react-redux";
import { UPDATE_DEPOSIT_REPORT } from "../../../../redux/actionTypes";

export default function DepositReportTable({
  translist,
  fetchTranslistLoading,
}) {
  const filters = useSelector((state) => state.filters.reports.depositReport);
  const dispatch = useDispatch();

  const handleChangePage = (newPage) => {
    dispatch({
      type: UPDATE_DEPOSIT_REPORT,
      payload: { currentPage: newPage },
    });
    // setPaginateTable({ ...filters, currentPage: newPage });
  };

  const handleChangeRowsPerPage = (newSize) => {
    dispatch({
      type: UPDATE_DEPOSIT_REPORT,
      payload: { currentPage: 0, pageSize: newSize },
    });
    // setPaginateTable({
    //   ...filters,
    //   currentPage: 0,
    //   pageSize: newSize,
    // });
  };

  const handleSortModelChange = (sort) => {
    console.log(sort);
    dispatch({
      type: UPDATE_DEPOSIT_REPORT,
      payload: { sortModel: sort },
    });
    // setPaginateTable((prevState) => ({ ...prevState, sortModel: sort }));
  };

  const createDataRow = (transaction) => {
    const type = transaction.type;
    return {
      id: transaction.ref_number,
      // type: capital(transaction?.type) || 'P2P',
      created_date: formatWithoutTimezone(
        transaction.created_date,
        "dd MMM yyyy HH:mm a"
      ),

      sender: transaction?.sender,
      mto: transaction?.sender_mto,

      customer_paid_amount: (() => {
        return formatCurrency(
          Number(transaction.customer_paid_amount),
          transaction?.send_currency?.decimal_place,
          transaction?.send_currency?.symbol,
          transaction?.send_currency?.symbol_prefix
        );
      })(),
      payment_method: transaction.deposit_type,
    };
  };

  const rows =
    translist?.results?.length > 0
      ? translist.results.map((transaction) => createDataRow(transaction))
      : [];

  const columns = [
    {
      field: "id",
      headerName: "Operation Reference Number",
      flex: 1,
      minWidth: 200,
      // maxWidth: 200,
      headerClassName: "header",
      align: "left",
      headerAlign: "left",
      sortable: false,
      renderCell: (params) => {
        return (
          <Tooltip
            placement="bottom"
            sx={{ cursor: "default" }}
            title={params.formattedValue}
          >
            <Typography variant="body2" style={tableCellStyle}>
              {params.formattedValue}
            </Typography>
          </Tooltip>
        );
      },
    },

    {
      field: "created_date",
      headerName: "Date",
      flex: 1,
      minWidth: 150,
      // maxWidth: 200,
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <Typography variant="body2" style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
    },
    {
      field: "sender",
      headerName: "Sender",
      flex: 1.5,
      minWidth: 200,
      // maxWidth: 200,
      textOverflow: "ellipsis",
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <Stack direction={"row"} sx={{ minWidth: 0, alignItems: "center" }}>
            <Avatar
              alt={params.formattedValue}
              sx={{ mr: 1, bgcolor: createAvatar(params.formattedValue).color }}
            >
              {createAvatar(params.formattedValue).name}
            </Avatar>
            <Typography
              variant="body2"
              sx={{
                color: "black",
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontWeight: "500",
              }}
            >
              {params.formattedValue}
            </Typography>
          </Stack>
        );
      },
    },

    {
      field: "mto",
      headerName: "MTO",
      flex: 1.5,
      minWidth: 200,
      // maxWidth: 200,
      textOverflow: "ellipsis",
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <Stack direction={"row"} sx={{ minWidth: 0, alignItems: "center" }}>
            <Avatar
              alt={params.formattedValue}
              sx={{ mr: 1, bgcolor: createAvatar(params.formattedValue).color }}
            >
              {createAvatar(params.formattedValue).name}
            </Avatar>
            <Typography
              variant="body2"
              sx={{
                color: "black",
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontWeight: "500",
              }}
            >
              {params.formattedValue}
            </Typography>
          </Stack>
        );
      },
    },

    {
      field: "customer_paid_amount",
      headerName: "Deposit Amount",
      flex: 1,
      minWidth: 150,
      // maxWidth: 200,
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <Typography variant="body2" style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
    },
    {
      field: "payment_method",
      headerName: "Payment Method",
      flex: 1,
      minWidth: 150,
      // maxWidth: 200,
      align: "center",
      headerAlign: "center",
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <Typography variant="body2" style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
    },
  ];

  return (
    <>
      <Box
        sx={{
          // height: 825,
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 0px 8px 0px",
          borderRadius: "8px",
          width: "100%",
          "& .MuiDataGrid-virtualScrollerRenderZone": {
            "& .MuiDataGrid-row": {
              // backgroundColor: 'rgba(235, 235, 235, .7)',
              fontWeight: 500,
              color: "#667085",
              fontFamily: "Inter, sans-serif",
              fontSize: "14px",
            },
          },
          "& .header": {
            backgroundColor: "#F4F4F4",
            "& div": {
              "& div": {
                "& div": {
                  "& div": {
                    fontSize: "14px",
                    fontWeight: 600,
                    fontFamily: "Inter, sans-serif",
                  },
                },
              },
            },
          },
        }}
      >
        <DataGrid
          autoHeight
          sx={{
            borderRadius: "8px",
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 0px 8px 0px",
            width: "100%",
          }}
          components={{
            LoadingOverlay: LinearProgress,
          }}
          // density={'compact'}
          rows={rows}
          columns={columns}
          rowHeight={50}
          disableColumnFilter={true}
          disableColumnMenu={true}
          pagination
          paginationMode="server"
          rowsPerPageOptions={rowsPerPageOptions}
          page={filters.currentPage}
          rowCount={translist.count}
          pageSize={filters.pageSize}
          onPageChange={(newPage) => handleChangePage(newPage)}
          onPageSizeChange={(newSize) => handleChangeRowsPerPage(newSize)}
          loading={fetchTranslistLoading}
          sortingMode="server"
          sortModel={filters.sortModel}
          onSortModelChange={handleSortModelChange}
        />
      </Box>
    </>
  );
}
