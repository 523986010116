import React, { useEffect, useState } from "react";
import BarChartLoader from "../../components/charts/BarChartLoader";
import HeroBarChartLoader from "../../components/charts/HeroBarChartLoader";
import AreaChartComp from "../../components/charts/AreaChartComp";
import { ResponsiveContainer, XAxis, Tooltip, BarChart, Bar } from "recharts";
import { format } from "date-fns";
import { Loader } from "rsuite";
import { Routes } from "../../routes";
import * as API from "../../ApiUrl";
import { merchantApi } from "../../redux/api/apiConfig";
import { useHistory } from "react-router-dom";
import { Button } from "rsuite";
import Dropdown from "react-bootstrap/Dropdown";
import dropDown from "../../assets/img/icons/dropDown.svg";
import { useSelector } from "react-redux";
import { formatCurrency, formatDate } from "../../utils/common";
import { toast } from "react-toastify";
import DoughnutChartComp from "../../components/charts/DoughnutChartComp";
import reload from "../../assets/img/icons/sync.svg";
import usePageTitle from "../../hooks/usePageTitle";
import { reportTimePeriods, timePeriodOptions } from "../reports/common";
import { statusColors } from "../../utils/common";
import { alpha } from "@mui/material/styles";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  IconButton,
  Stack,
  Tooltip as MuiTooltip,
  Typography,
  MenuItem,
} from "@mui/material";
import { FilterDropdown } from "../reports/components";
import { StyledDatePicker } from "../../components/datePicker";
import { ButtonPrimary } from "../../components";

const doughnutChartColors = [
  statusColors.colors["application submitted"],
  statusColors.colors.complete,
  statusColors.colors.rejected,
  statusColors.colors.pending,
];

export default (props) => {
  const [showInward, setShowInward] = useState(true);
  const [showOutward, setShowOutward] = useState(true);
  const [showFees, setShowFees] = useState(true);
  const [chartData, setChartData] = useState([]);
  const [timePeriod, setTimePeriod] = useState("day");
  const [timePeriodTitle, setTimePeriodTitle] = useState("Daily");
  const [reportTimePeriod, setReportTimePeriod] = useState({
    from: new Date(reportTimePeriods[1].from),
    to: null,
    period: reportTimePeriods[1],
  });
  const [redemptionData, setRedemptionData] = useState(0);
  const [commissionReport, setCommissionReport] = useState({
    commissionData: [],
    total: 0,
  });

  const [customerBaseReport, setCustomerBaseReport] = useState({
    customerBaseData: [],
    total: 0,
  });

  const [avgRemittanceData, setAvgRemittanceData] = useState({ data: [] });

  const [currencyFlow, setCurrencyFlow] = useState({
    totals: { inflow: 0, outflow: 0 },
  });
  const [customerReport, setCustomerReport] = useState({});

  const [fetchChartDataLoading, setFetchChartDataLoading] = useState(false);
  const [reportLoading, setReportLoading] = useState({
    revenue: false,
    currencyFlow: false,
    customer: false,
    members: false,
    customer_base: false,
  });
  const [refreshReports, setRefreshReports] = useState(0);
  const [refreshAnalytics, setRefreshAnalytics] = useState(0);
  const [payoutLoading, setPayoutLoading] = useState(false);
  const history = useHistory();

  usePageTitle("", "");

  const {
    profileReducer: {
      user: {
        is_mediator: isMediator,
        mto: { currency: userCurrency } = {},
      } = {},
    } = {},
  } = useSelector((state) => state);
  const {
    permission,
    user: {
      role: { key },
    },
  } = useSelector((state) => state.profileReducer);

  useEffect(() => {
    getRevenueForBarChart();
    getRedemptionBalance();
  }, [timePeriod, refreshAnalytics]);

  useEffect(() => {
    localStorage.setItem("refreshTimestamp", new Date().getTime());
    getCommissionReport();
    getCustomerBaseReport();
    // getCurrencyFlow();
    getCustomerReport();
    getAverageRemittanceReport();
  }, [reportTimePeriod, refreshReports]);

  const getAverageRemittanceReport = async () => {
    try {
      setReportLoading((prevState) => ({ ...prevState, avgRemittance: true }));
      const params = {
        from_date: formatDate(reportTimePeriod.from, "yyyy-MM-dd"),
        to_date: formatDate(reportTimePeriod.to, "yyyy-MM-dd"),
      };

      const refreshTimestamp = new Date().getTime();
      const response = await merchantApi.get(API.AVERAGE_REMITTANCE, {
        headers: {
          "X-REFRESH": String(refreshTimestamp),
        },
        params,
      });

      let data = [{ Outward: 0, Inward: 0, Fees: 0, name: "" }];

      if (response.data.data.length > 0) {
        data = response.data.data.map((item) => {
          return {
            Outward: item.outward,
            Inward: item.inward,
            Fees: item.fees,
            name: item.period,
          };
        });
      }
      setAvgRemittanceData({ data });
      setReportLoading((prevState) => ({ ...prevState, avgRemittance: false }));
    } catch (error) {
      console.log(error);
      toast.error(error?.data?.data?.message || "Something went wrong");
      setReportLoading((prevState) => ({ ...prevState, avgRemittance: false }));
    }
  };

  const getRedemptionBalance = async () => {
    setPayoutLoading(true);

    const refreshTimestamp = new Date().getTime();
    const params = {
      tz_offset: new Date().getTimezoneOffset() / 60,
    };
    try {
      const response = await merchantApi.get(
        API.PENDING_AND_CURRENT_REDEMPTION,
        {
          headers: {
            "X-REFRESH": String(refreshTimestamp),
          },
          params,
        }
      );
      setRedemptionData(response.data.data);
      setPayoutLoading(false);
    } catch (error) {
      setPayoutLoading(false);
      console.log(error);
      toast.error(error?.data?.data?.message || "Something went wrong");
      // toast.error(error?.response?.data?.message || 'Something went wrong');
    }
  };

  const getRevenueForBarChart = async () => {
    setFetchChartDataLoading(true);

    const refreshTimestamp = new Date().getTime();
    const params = {
      group_by: timePeriod,
      tz_offset: new Date().getTimezoneOffset() / 60,
      // tz_offset: 8,
    };
    try {
      const response = await merchantApi.get(API.DASHBOARD, {
        headers: {
          "X-REFRESH": String(refreshTimestamp),
        },
        params,
      });

      let total = {
        inward: 0,
        outward: 0,
      };
      let data = [{ Outward: 0, Inward: 0, Fees: 0, name: "" }];

      if (response.data.data.result.length > 0) {
        data = response.data.data.result.map((item) => {
          total = {
            inward: total.inward + item.inward,
            outward: total.outward + item.outward,
          };

          return {
            Outward: item.outward,
            Inward: item.inward,
            Fees: item.fees,
            name: item.name,
          };
        });
      }
      setChartData({ data, total });
      setFetchChartDataLoading(false);
    } catch (error) {
      toast.error(error?.data?.data?.message || "Something went wrong");
      setFetchChartDataLoading(false);
    }
  };
  const getCommissionReport = async () => {
    try {
      setReportLoading((prevState) => ({ ...prevState, commission: true }));

      const refreshTimestamp = new Date().getTime();
      const params = {
        from_date: formatDate(reportTimePeriod.from, "yyyy-MM-dd"),
        to_date: formatDate(reportTimePeriod.to, "yyyy-MM-dd"),
      };
      const response = await merchantApi.get(API.COMMISSIONS_HISTORY, {
        headers: {
          "X-REFRESH": String(refreshTimestamp),
        },
        params,
      });
      let total = 0;

      let inwardTotal = 0;

      let outwardTotal = 0;

      const commissionData = response.data.data.map((item) => {
        total = total + item.total;
        inwardTotal = inwardTotal + item.inward;
        outwardTotal = outwardTotal + item.outward;
        return {
          name: item.period,
          Inward: item.inward,
          Outward: item.outward,
          Total: item.total,
        };
      });

      setCommissionReport({
        commissionData,
        total,
        inwardTotal,
        outwardTotal,
      });

      setReportLoading((prevState) => ({ ...prevState, commission: false }));
    } catch (error) {
      toast.error(error?.data?.data?.message || "Something went wrong");
      setReportLoading((prevState) => ({ ...prevState, commission: false }));
    }
  };

  const getCustomerBaseReport = async () => {
    const refreshTimestamp = new Date().getTime();
    try {
      setReportLoading((prevState) => ({ ...prevState, customer_base: true }));
      const params = {
        from_date: formatDate(reportTimePeriod.from, "yyyy-MM-dd"),
        to_date: formatDate(reportTimePeriod.to, "yyyy-MM-dd"),
      };
      const response = await merchantApi.get(API.CUSTOMER_BASE, {
        headers: {
          "X-REFRESH": String(refreshTimestamp),
        },
        params,
      });
      const { total_count, total_receiver, total_sender } = response.data.data;

      // let total = 0;

      // let inwardTotal = 0;

      // let outwardTotal = 0;

      const customerBaseData = response.data.data.history.map((item) => {
        // total = total + item.total;
        // inwardTotal = inwardTotal + item.inward;
        // outwardTotal = outwardTotal + item.outward;
        return {
          name: item.period,
          Senders: item.sender,
          Receivers: item.receiver,
          Total: item.total,
        };
      });

      setCustomerBaseReport({
        customerBaseData,
        total_count,
        total_receiver,
        total_sender,
      });

      setReportLoading((prevState) => ({
        ...prevState,
        customer_base: false,
      }));
    } catch (error) {
      toast.error(error?.data?.data?.message || "Something went wrong");
      setReportLoading((prevState) => ({ ...prevState, customer_base: false }));
    }
  };
  // const getCurrencyFlow = async () => {
  //   const refreshTimestamp = new Date().getTime();
  //   try {
  //     setReportLoading((prevState) => ({ ...prevState, currencyFlow: true }));
  //     const params = {
  //       from_date: formatDate(reportTimePeriod.from, 'yyyy-MM-dd'),
  //       to_date: formatDate(reportTimePeriod.to, 'yyyy-MM-dd'),
  //     };
  //     const response = await merchantApi.get(API.COINWISE, {
  //       headers: {
  //         'X-REFRESH': String(refreshTimestamp),
  //       },
  //       params,
  //     });

  //     const { inflow, outflow, total_inward, total_outward } = response.data.data;

  //     const totals = {
  //       inflow: total_inward,
  //       outflow: total_outward,
  //     };

  //     let inwardFlow = inflow.slice(0, 4).map((item, i) => ({
  //       name: item.country,
  //       value: item.total,
  //       fill: alpha(doughnutChartColors[i], 0.6),
  //     }));

  //     const remainingCoinsInflow = inflow.slice(4).reduce((prev, curr) => prev + curr.inward, 0);

  //     const finalInfowData = [
  //       ...inwardFlow,
  //       {
  //         name: 'Others',
  //         value: remainingCoinsInflow,
  //         fill: doughnutChartColors[4],
  //       },
  //     ];

  //     let outwardFlow = outflow.slice(0, 4).map((item, i) => ({
  //       name: item.country,
  //       value: item.total,
  //       fill: alpha(doughnutChartColors[i], 0.6),
  //     }));

  //     const remainingCoinsOutflow = outflow.slice(4).reduce((prev, curr) => prev + curr.inward, 0);

  //     const finalOutflowData = [
  //       ...outwardFlow,
  //       {
  //         name: 'Others',
  //         value: remainingCoinsOutflow,
  //         fill: doughnutChartColors[4],
  //       },
  //     ];

  //     setCurrencyFlow({ finalInfowData, finalOutflowData, totals });

  //     setReportLoading((prevState) => ({ ...prevState, currencyFlow: false }));
  //   } catch (error) {
  //     console.log(error);
  //     toast.error(error?.data?.data?.message || 'Something went wrong');
  //     setReportLoading((prevState) => ({ ...prevState, currencyFlow: false }));
  //   }
  // };

  const getCustomerReport = async () => {
    const refreshTimestamp = new Date().getTime();
    try {
      setReportLoading((prevState) => ({ ...prevState, customer_base: true }));
      const params = {
        from_date: formatDate(reportTimePeriod.from, "yyyy-MM-dd"),
        to_date: formatDate(reportTimePeriod.to, "yyyy-MM-dd"),
      };
      const response = await merchantApi.get(API.CUSTOMER_HISTORY, {
        headers: {
          "X-REFRESH": String(refreshTimestamp),
        },
        params,
      });

      const { new_customer: newCustomers, old_customer: repeatCustomers } =
        response.data.data;

      const retentionPercent =
        newCustomers === 0 && repeatCustomers === 0
          ? 0
          : (repeatCustomers / (newCustomers + repeatCustomers)) * 100;

      setCustomerReport({
        retentionPercent,
        customerRetentionData: [
          {
            name: "Repeat Customers",
            value: repeatCustomers,
            fill: alpha(doughnutChartColors[0], 0.6),
          },
          {
            name: "New Customers",
            value: newCustomers,
            fill: alpha(doughnutChartColors[1], 0.6),
          },
        ],
      });

      setReportLoading((prevState) => ({ ...prevState, customer: false }));
    } catch (error) {
      toast.error(error?.data?.data?.message || "Something went wrong");
      setReportLoading((prevState) => ({ ...prevState, customer: false }));
    }
  };

  const timePeriodChangeHandler = (period) => {
    const periodData = timePeriodOptions.filter(
      (option) => option.value === period
    );

    setTimePeriod(period);
    setTimePeriodTitle(periodData[0].name);
  };

  const toggleInward = () => {
    setShowInward(true);
    setShowOutward(false);
    setShowFees(false);
  };
  const toggleOutward = () => {
    setShowOutward(true);
    setShowFees(false);
    setShowInward(false);
  };
  const toggleFees = () => {
    setShowFees(true);
    setShowInward(false);
    setShowOutward(false);
  };

  const reset = () => {
    setShowOutward(true);
    setShowInward(true);
    setShowFees(true);
  };

  const payNowClickHandler = () => {
    history.push(Routes.AddOutwardRemittance.path);
  };
  return (
    <>
      <div className="page-wrapper stb-page-wrapper stb-ph-8 bg-white">
        <div className="tabing stb-h-100 p-xl-4 p-3">
          <>
            <div className="reports bg-white radi">
              <div className="d-flex align-items-center pb-4 space-between">
                <div className="col-lg-3">
                  <h3 className="m-0 page-title">
                    Analytics
                    <span
                      className="sync"
                      onClick={() => {
                        localStorage.setItem(
                          "refreshTimestamp",
                          new Date().getTime()
                        );
                        setRefreshAnalytics((prevState) => prevState + 1);
                      }}
                    >
                      {" "}
                      <img
                        className={`${fetchChartDataLoading ? "reload" : null}`}
                        src={reload}
                        alt="reload"
                      />
                    </span>
                  </h3>
                  <p>View your stats</p>
                </div>
                {permission?.remittances?.outward?.permission?.add[key] && (
                  <div className="d-flex align-items-center justify-end gap-4">
                    <div style={{ width: "fit-content" }}>
                      <ButtonPrimary onClick={payNowClickHandler}>
                        <span>Pay Now</span>
                      </ButtonPrimary>
                    </div>
                  </div>
                )}
              </div>
              <div className="dash-hero">
                <div className="hero-graph" style={{ paddingBottom: 0 }}>
                  <div className="hero-filters">
                    <div>
                      <p className="report-title">Operation</p>
                      <Stack
                        sx={{ flexDirection: "row", alignItems: "center" }}
                      >
                        <p>Inward</p>
                        <MuiTooltip
                          title="Inward remittances excluding fees"
                          placement="top"
                        >
                          <IconButton>
                            <InfoOutlinedIcon fontSize="small" />
                          </IconButton>
                        </MuiTooltip>
                      </Stack>
                      <h3 className="page-title">
                        {formatCurrency(
                          chartData?.total?.inward,
                          userCurrency?.decimal_place,
                          userCurrency?.code,
                          userCurrency?.symbol_prefix
                        )}
                        {/* {currency.symbol} {dataPointName.currentRevenue} */}
                      </h3>
                      {/* <p>
                          {currentTransTime === '' ? '--' : currentTransTime}
                        </p> */}
                    </div>
                    <div>
                      <p className="report-title">Operation</p>{" "}
                      <Stack
                        sx={{ flexDirection: "row", alignItems: "center" }}
                      >
                        <p>Outward</p>
                        <MuiTooltip
                          title="Outward remittances excluding fees"
                          placement="top"
                        >
                          <IconButton>
                            <InfoOutlinedIcon fontSize="small" />
                          </IconButton>
                        </MuiTooltip>
                      </Stack>
                      <h3 className="page-title">
                        {formatCurrency(
                          chartData?.total?.outward,
                          userCurrency?.decimal_place,
                          userCurrency?.code,
                          userCurrency?.symbol_prefix
                        )}
                        {/* {currency.symbol} {dataPointName.previousRevenue} */}
                      </h3>
                      {/* <p>{prevTransTime === '' ? '--' : prevTransTime}</p> */}
                    </div>
                    <div>
                      <Dropdown align="end" className="w-100 home-dropdown">
                        <Dropdown.Toggle id="dropdown-basic">
                          {timePeriodTitle || "Select Period"}
                          <img
                            src={dropDown}
                            style={{ marginLeft: "4px" }}
                            alt="drop-down"
                          />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="super-colors">
                          {timePeriodOptions.map((period, i) => (
                            <Dropdown.Item
                              // as="button"
                              value={period.value}
                              onClick={() => {
                                timePeriodChangeHandler(period.value);
                              }}
                              key={i + "res"}
                            >
                              {period.name}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <div className="hero-stat-type">
                      <div
                        className={`stat ${showInward ? "" : "opacity-5"}`}
                        onClick={toggleInward}
                      >
                        <span className="stat-selector yellow-dark"></span>
                        <span>Inward</span>
                      </div>
                      <div
                        className={`stat ${showOutward ? "" : "opacity-5"}`}
                        onClick={toggleOutward}
                      >
                        <span className="stat-selector yellow"></span>
                        <span>Outward</span>
                      </div>
                      <div
                        className={`stat ${showFees ? "" : "opacity-5"}`}
                        onClick={toggleFees}
                      >
                        <span className="stat-selector yellow-light"></span>
                        <span>Fees Earned</span>
                      </div>
                      <div className="stat" onClick={reset}>
                        <span className="stat-selector"></span>
                        <span>Reset</span>
                      </div>
                    </div>
                  </div>
                  <div className="hero-chart">
                    {fetchChartDataLoading ? (
                      <HeroBarChartLoader />
                    ) : (
                      <ResponsiveContainer height="95%" width="100%">
                        <BarChart
                          width={400}
                          height={300}
                          data={chartData.data}
                          margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 0,
                          }}
                          barCategoryGap={20}
                        >
                          <Tooltip />
                          <XAxis
                            scale="point"
                            dataKey="name"
                            interval="preserveStartEnd"
                          />
                          {showInward && (
                            <Bar
                              barSize={10}
                              dataKey="Inward"
                              fill="#B38600"
                              radius={[10, 10, 0, 0]}
                            />
                          )}
                          {showOutward && (
                            <Bar
                              barSize={10}
                              dataKey="Outward"
                              fill="#FCBD00"
                              radius={[10, 10, 0, 0]}
                            />
                          )}
                          {showFees && (
                            <Bar
                              barSize={10}
                              dataKey="Fees"
                              fill="#FDD354"
                              radius={[10, 10, 0, 0]}
                            />
                          )}
                        </BarChart>
                      </ResponsiveContainer>
                    )}
                  </div>
                </div>
                <div className="hero-numbers">
                  <div className="hero-balance">
                    <div style={{ position: "relative" }}>
                      <p>
                        Settlements this month{" "}
                        <span
                          className="sync"
                          onClick={() => {
                            localStorage.setItem(
                              "refreshTimestamp",
                              new Date().getTime()
                            );
                            getRedemptionBalance();
                          }}
                        >
                          {" "}
                          <img
                            className={`${payoutLoading ? "reload" : null}`}
                            src={reload}
                            alt="reload"
                          />
                        </span>{" "}
                      </p>
                      {payoutLoading ? (
                        <div style={{ position: "absolute", top: "50%" }}>
                          <Loader content="loading..." />
                        </div>
                      ) : (
                        <h3 className="page-title">
                          {formatCurrency(
                            redemptionData?.this_month_redemption ?? 0,
                            userCurrency?.decimal_place,
                            userCurrency?.code,
                            userCurrency?.symbol_prefix
                          )}
                        </h3>
                      )}
                    </div>
                    <Button
                      className="btn white-btn action-button basis-30"
                      onClick={() => {
                        history.push(Routes.RedemptionsRaised.path);
                      }}
                      appearance="subtle"
                    >
                      View
                    </Button>
                  </div>

                  <div className="hero-payout">
                    <div style={{ position: "relative" }}>
                      <p>
                        Pending Settlements{" "}
                        <span
                          className="sync"
                          onClick={() => {
                            localStorage.setItem(
                              "refreshTimestamp",
                              new Date().getTime()
                            );
                            getRedemptionBalance();
                          }}
                        >
                          {" "}
                          <img
                            className={`${payoutLoading ? "reload" : null}`}
                            src={reload}
                            alt="reload"
                          />
                        </span>{" "}
                      </p>
                      {payoutLoading ? (
                        <div style={{ position: "absolute", top: "50%" }}>
                          <Loader content="loading..." />
                        </div>
                      ) : (
                        <h3 className="page-title">
                          {formatCurrency(
                            redemptionData?.pending_redemption ?? 0,
                            userCurrency?.decimal_place,
                            userCurrency?.code,
                            userCurrency?.symbol_prefix
                          )}
                        </h3>
                      )}
                    </div>
                    <Button
                      className="btn white-btn action-button basis-30"
                      onClick={() => {
                        history.push(Routes.AvailableRedemptions.path);
                      }}
                      appearance="subtle"
                    >
                      View
                    </Button>
                  </div>
                </div>
              </div>
            </div>

            <section>
              <div className="d-flex align-items-start flex-column mt-4 gap-2">
                <div className="col-lg-4">
                  <h3 className="m-0 page-title">
                    Reports Overview
                    <span
                      className="sync"
                      onClick={() => {
                        localStorage.setItem(
                          "refreshTimestamp",
                          new Date().getTime()
                        );
                        setRefreshReports((prevState) => prevState + 1);
                      }}
                    >
                      {" "}
                      <img
                        className={`${
                          reportLoading.customer ||
                          reportLoading.members ||
                          reportLoading.currencyFlow ||
                          reportLoading.revenue
                            ? "reload"
                            : null
                        }`}
                        src={reload}
                        alt="reload"
                      />
                    </span>
                  </h3>
                </div>
                <div className="d-flex align-items-center justify-end gap-4">
                  <FilterDropdown
                    name="timeperiod"
                    type="text"
                    label="Timeperiod"
                    options={reportTimePeriods}
                    onChange={(e) => {
                      const name = e.target.value;
                      const period = reportTimePeriods.find(
                        (report) => report.name === name
                      );

                      let toValue;
                      if (reportTimePeriod.to) {
                        toValue =
                          new Date(period.from) > new Date(reportTimePeriod.to)
                            ? null
                            : reportTimePeriod.to;
                      } else {
                        toValue = null;
                      }
                      setReportTimePeriod((prevState) => ({
                        ...prevState,
                        from: new Date(period.from),
                        to: toValue,
                        period,
                      }));
                    }}
                    value={reportTimePeriod?.period?.name}
                    size={"small"}
                    render={(period, i) => (
                      <MenuItem key={period.name} value={period.name}>
                        {period.name}
                      </MenuItem>
                    )}
                  />
                  {/* <Dropdown as={ButtonGroup} className="w-100">
                      <Dropdown.Toggle id="dropdown-basic">
                        {reportTimePeriodTitle || 'Select Period'}
                        <img
                          className="dropdown-icon"
                          src={dropDown}
                          style={{ marginLeft: '4px' }}
                          alt="drop-down"
                        />
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="super-colors">
                        {reportTimePeriods.map((period, i) => (
                          <Dropdown.Item
                            as="button"
                            value={period.name}
                            onClick={() => {
                              reportTimePeriodChangeHandler(
                                period.from,
                                period.to,
                                period.name
                              );
                            }}
                            key={i + 'res'}
                          >
                            {period.name}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown> */}
                  <Stack sx={{ flexDirection: "row", gap: 1 }}>
                    <Stack sx={{ width: "165px", flex: 1 }}>
                      <StyledDatePicker
                        value={reportTimePeriod.from}
                        disableFuture
                        format={"dd/MM/yyyy"}
                        onChange={(newValue) => {
                          setReportTimePeriod((prevState) => ({
                            ...prevState,
                            from: new Date(format(newValue, "yyyy/MM/dd")),
                            to:
                              newValue > reportTimePeriod.to
                                ? undefined
                                : reportTimePeriod.to,
                          }));
                        }}
                      />
                    </Stack>

                    <Stack sx={{ width: "165px", flex: 1 }}>
                      <StyledDatePicker
                        value={reportTimePeriod.to}
                        disableFuture
                        // minDate={reportTimePeriod.from ?? undefined}
                        format={"dd/MM/yyyy"}
                        onChange={(newValue) => {
                          setReportTimePeriod((prevState) => ({
                            ...prevState,
                            from:
                              newValue < reportTimePeriod.from
                                ? undefined
                                : reportTimePeriod.from,
                            to: new Date(format(newValue, "yyyy/MM/dd")),
                          }));
                        }}
                      />
                    </Stack>
                  </Stack>

                  {/* <DateRangePicker
                      cleanable
                      placeholder="Filter Date"
                      className="w-100 dateInput"
                      size="lg"
                      style={{ height: '40px' }}
                      onOk={handleDateFilter}
                      value={calenderValue}
                      placement="auto"
                      showOneCalendar={true}
                      disabledDate={afterToday()}
                      onClean={(dara) => {
                        reportTimePeriodChangeHandler(
                          reportTimePeriods[0].from,
                          reportTimePeriods[0].to,
                          reportTimePeriods[0].name
                        );
                      }}
                    /> */}
                </div>
              </div>
              <div className="reports-container mt-4">
                {/* <div className='report'>
                    <Stack sx={{ flexDirection: 'row', alignItems: 'center' }}>
                      <p className='report-title'>Inward Flow by Country</p>
                      <MuiTooltip title='Inward remittances including fees' placement='top'>
                        <IconButton>
                          <InfoOutlinedIcon fontSize='small' />
                        </IconButton>
                      </MuiTooltip>
                    </Stack>
                    <p className='report-data'>
                      {formatCurrency(
                        currencyFlow?.totals?.inflow || 0,
                        userCurrency?.decimal_place,
                        userCurrency?.code,
                        userCurrency?.symbol_prefix
                      )}
                    </p>

                    <DoughnutChartComp data={currencyFlow.finalInfowData} loading={reportLoading.currencyFlow} />
                  </div> */}

                {/* <div className='report'>
                    <Stack sx={{ flexDirection: 'row', alignItems: 'center' }}>
                      <p className='report-title'>Outward Flow by Country</p>
                      <MuiTooltip title='Deposit amount paid by clients (Payout Done & Redemption Complete)' placement='top'>
                        <IconButton>
                          <InfoOutlinedIcon fontSize='small' />
                        </IconButton>
                      </MuiTooltip>
                    </Stack>
                    <p className='report-data'>
                      {formatCurrency(currencyFlow?.totals?.outflow, userCurrency?.decimal_place, userCurrency?.code, userCurrency?.symbol_prefix)}
                    </p>
                    <DoughnutChartComp data={currencyFlow.finalOutflowData} loading={reportLoading.currencyFlow} />
                  </div> */}

                {/* <div className="report">
                    <p className="report-title">Team Size</p>
                    <p className="report-data">43</p>
                    {reportLoading.members ? (
                      <BarChartLoader />
                    ) : (
                      <ResponsiveContainer height="95%" width="100%">
                        <BarChart
                          width={400}
                          height={300}
                          data={teamSize}
                          margin={{
                            left: 5,
                            right: 5,
                            top: 5,
                            bottom: 30,
                          }}
                          barCategoryGap={20}
                        >
                          <Tooltip
                            content={<CustomTooltip valueLabel="Count" />}
                          />
                          <XAxis
                            scale="point"
                            dataKey="name"
                            interval="preserveStartEnd"
                          />

                          <Bar
                            barSize={10}
                            dataKey="value"
                            fill={doughnutChartColors[0]}
                            radius={[10, 10, 0, 0]}
                          />
                        </BarChart>
                      </ResponsiveContainer>
                    )}
                  </div> */}

                <div className="report">
                  <p className="report-title">Customer Retention</p>
                  <p className="report-data">
                    {customerReport.retentionPercent}%
                  </p>

                  <DoughnutChartComp
                    data={customerReport.customerRetentionData}
                    loading={reportLoading.customer}
                  />
                </div>
                <div className="report">
                  <p className="report-title">Average Operation Value</p>
                  <p className="report-data"></p>
                  {/* <p className="report-data">Outward: EUR 2,343</p> */}

                  {reportLoading.customer ? (
                    <BarChartLoader />
                  ) : (
                    <ResponsiveContainer height="100%" width="100%">
                      <BarChart
                        width={400}
                        height={300}
                        data={avgRemittanceData.data}
                        margin={{
                          left: 15,
                          right: 15,
                          top: 15,
                          bottom: 5,
                        }}
                        barCategoryGap={20}
                      >
                        <Tooltip />
                        <XAxis
                          scale="point"
                          dataKey="name"
                          interval="preserveStartEnd"
                        />

                        <Bar
                          barSize={10}
                          dataKey="Inward"
                          fill={doughnutChartColors[0]}
                          radius={[10, 10, 0, 0]}
                        />
                        <Bar
                          barSize={10}
                          dataKey="Outward"
                          fill="#FCBD00"
                          radius={[10, 10, 0, 0]}
                        />
                      </BarChart>
                    </ResponsiveContainer>
                  )}
                </div>
                <div className="report">
                  <p className="report-title">Commissions</p>
                  <Stack
                    sx={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Stack sx={{ flexDirection: "column" }}>
                      <Typography variant={"caption"}>Inward</Typography>
                      <p className="report-data">
                        {formatCurrency(
                          commissionReport?.inwardTotal,
                          userCurrency?.decimal_place,
                          userCurrency?.code,
                          userCurrency?.symbol_prefix
                        )}
                      </p>
                    </Stack>

                    <Stack sx={{ flexDirection: "column" }}>
                      <Typography variant={"caption"}>Outward</Typography>
                      <p className="report-data">
                        {formatCurrency(
                          commissionReport?.outwardTotal,
                          userCurrency?.decimal_place,
                          userCurrency?.code,
                          userCurrency?.symbol_prefix
                        )}
                      </p>
                    </Stack>
                    <Stack sx={{ flexDirection: "column" }}>
                      <Typography variant={"caption"}>Total</Typography>
                      <p className="report-data">
                        {formatCurrency(
                          commissionReport?.total,
                          userCurrency?.decimal_place,
                          userCurrency?.code,
                          userCurrency?.symbol_prefix
                        )}
                      </p>
                    </Stack>
                  </Stack>

                  {reportLoading.commission ? (
                    <BarChartLoader />
                  ) : (
                    <AreaChartComp
                      data={commissionReport.commissionData}
                      lines={["Total", "Inward", "Outward"]}
                    />
                  )}
                </div>
                <div className="report">
                  <p className="report-title">Customer Base</p>
                  <Stack
                    sx={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Stack sx={{ flexDirection: "column" }}>
                      <Typography variant={"caption"}>Senders</Typography>
                      <p className="report-data">
                        {customerBaseReport?.total_sender ?? "--"}
                      </p>
                    </Stack>

                    <Stack sx={{ flexDirection: "column" }}>
                      <Typography variant={"caption"}>Receivers</Typography>
                      <p className="report-data">
                        {customerBaseReport?.total_receiver ?? "--"}
                      </p>
                    </Stack>
                    <Stack sx={{ flexDirection: "column" }}>
                      <Typography variant={"caption"}>Total</Typography>
                      <p className="report-data">
                        {customerBaseReport?.total_count ?? "--"}
                      </p>
                    </Stack>
                  </Stack>

                  {reportLoading.commission ? (
                    <BarChartLoader />
                  ) : (
                    <AreaChartComp
                      data={customerBaseReport.customerBaseData}
                      lines={["Total", "Senders", "Receivers"]}
                    />
                  )}
                </div>
              </div>
            </section>
          </>
        </div>
      </div>
    </>
  );
};
