import React, { useState, useEffect, useCallback } from "react";
import { merchantApi } from "../../../../redux/api/apiConfig";
import { OutwardFeesTable } from "./components";
import { AddNewSlabModal } from "../feesComponents/";
import "rsuite/dist/styles/rsuite-default.css";
import { Button } from "@themesberg/react-bootstrap";
import { toast } from "react-toastify";
import * as API from "../../../../ApiUrl";
import usePageTitle from "../../../../hooks/usePageTitle";
import { Box, Stack } from "@mui/material";
import { ButtonPrimary } from "../../../../components";
import { useSelector } from "react-redux";

const OutwardRemittanceFees = () => {
  const [slabs, setSlabs] = useState([]);
  const [fetchSlabsLoading, setFetchSlabsLoading] = useState(true);
  const [showAddSlabModal, setShowAddSlabModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [slabToEdit, setSlabToEdit] = useState({});
  const [paginateTable, setPaginateTable] = useState({
    currentPage: 0,
    pageSize: 7,
    search: "",
  });

  const permission = useSelector(
    (state) =>
      state.profileReducer?.permission?.configurations?.fees
        ?.outwardRemittanceFees?.permission
  );
  const role = useSelector((state) => state.profileReducer?.user?.role?.key);

  usePageTitle(
    "Outward Operation Fees",
    "Define commissions in base currency. All conversions will be done in real-time."
  );

  useEffect(() => {
    console.log(slabToEdit);
  }, [slabToEdit]);

  const toggleAddNewSlabModal = () => {
    if (showAddSlabModal) {
      getFeeSlabs();
      setIsEdit(false);
      setSlabToEdit({});
    }
    setShowAddSlabModal((prevState) => !prevState);
  };

  const deleteSlab = async (id) => {
    setFetchSlabsLoading(true);
    try {
      const response = await merchantApi.delete(`${API.FEE}${id}/`);
      console.log(response);
      // setSlabs(response.data);
      // resetFilters();
      getFeeSlabs();
      setFetchSlabsLoading(false);
    } catch (error) {
      toast.error(error?.data?.data?.message || "Something went wrong");
      // toast.error(error.response.data.message);
      setFetchSlabsLoading(false);
    }
  };

  const getFeeSlabs = useCallback(async () => {
    setFetchSlabsLoading(true);
    try {
      const response = await merchantApi.get(`${API.FEE}`, {
        params: {
          offset: paginateTable.currentPage * paginateTable.pageSize,
          limit: paginateTable.pageSize,
          search: paginateTable.search,
          side: "outward",
        },
      });
      console.log(response);
      setSlabs(response.data);
      setFetchSlabsLoading(false);
    } catch (error) {
      toast.error(error?.data?.data?.message || "Something went wrong");
      setFetchSlabsLoading(false);
    }
  }, [paginateTable]);

  useEffect(() => {
    const timer = setTimeout(() => {
      getFeeSlabs();
    }, 500);
    return () => clearTimeout(timer);
  }, [
    paginateTable.currentPage,
    paginateTable.pageSize,
    paginateTable.search,
    getFeeSlabs,
  ]);

  return (
    <Box sx={{ padding: "0 24px 24px" }}>
      {permission?.newSlab[role] && (
        <Stack
          direction={"row"}
          justifyContent={"flex-end"}
          width={"100%"}
          mb={2}
        >
          <ButtonPrimary onClick={toggleAddNewSlabModal}>
            New Slab
          </ButtonPrimary>
        </Stack>
      )}
      <OutwardFeesTable
        fetchSlabsLoading={fetchSlabsLoading}
        slabs={slabs}
        paginateTable={paginateTable}
        setPaginateTable={setPaginateTable}
        setSlabToEdit={setSlabToEdit}
        setIsEdit={setIsEdit}
        toggleAddNewSlabModal={toggleAddNewSlabModal}
        deleteSlab={deleteSlab}
      />
      {showAddSlabModal && (
        <AddNewSlabModal
          onClose={toggleAddNewSlabModal}
          isOpen={showAddSlabModal}
          isEdit={isEdit}
          slabToEdit={slabToEdit}
          setSlabToEdit={setSlabToEdit}
          // This is to trigger re-render after adding user to fetch new list
          onSubmit={() => {
            toggleAddNewSlabModal();
            getFeeSlabs();
          }}
          side={"outward"}
        />
      )}
    </Box>
  );
};

export default OutwardRemittanceFees;
