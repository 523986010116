import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Stack, Card } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { ButtonPrimary, TextFieldPassword } from '../../components';
import { merchantApi } from '../../redux/api/apiConfig';
import { UPDATE_PASSWORD } from '../../ApiUrl';
import * as actionTypes from '../../redux/actionTypes';
import { Routes } from '../../routes';
import { toast } from 'react-toastify';

const ResetPassword = () => {
  const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
  const validationSchema = yup.object({
    old_password: yup
      .string('Enter your old password')
      .required('Old password is required'),
    new_password: yup
      .string('Enter your new password')
      .matches(passwordRegex, 'Please enter mix of uppercase, lowercase, special character & number')
      .min(8, 'New Password should be of minimum 8 characters length')
      .required('New password is required'),

    confirm_password: yup
      .string('Enter your confirm password')
      .oneOf([yup.ref('new_password')], 'Password does not match')
      .required('Confirm Password is Required'),
  });

  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();

  const onUpdate = async (values) => {
    const { old_password, new_password } = values;
    try {
      setLoading(true);
      const response = await merchantApi.post(UPDATE_PASSWORD, {
        old_password,
        new_password,
      });
      localStorage.clear();
      dispatch({ type: actionTypes.CLEAR_STORE_DATA });
      // window.location.href = '/';
      setLoading(false);
      history.push(Routes.Signin.path);
    } catch (error) {
      toast.error(error?.data?.data?.message || 'Something went wrong');
      // setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {},
    validateOnBlur: true,
    validationSchema: validationSchema,
    onSubmit: onUpdate,
    validateOnMount: true,
  });

  return (
    <Card sx={{ width: { xs: '100%', md: '50%' } }}>
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={2} sx={{ p: 3 }}>
          <Stack spacing={1} direction={'column'} alignItems={'center'}>
            <TextFieldPassword formik={formik} name={'old_password'} label={'Old Password'} />
            <TextFieldPassword formik={formik} name={'new_password'} label={'New Password'} />
            <TextFieldPassword formik={formik} name={'confirm_password'} label={'Confirm Password'} />
          </Stack>
          <Stack justifyContent={'flex-end'} direction={'row'}>
            <ButtonPrimary type={'submit'} disabled={!formik.isValid} loading={loading}>
              Update
            </ButtonPrimary>
          </Stack>
        </Stack>
      </form>
    </Card>
  );
};

export default ResetPassword;
