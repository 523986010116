import React, { useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import { Form, Button, Container } from '@themesberg/react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TextField from '@material-ui/core/TextField';
import * as actionTypes from '../../redux/actionTypes';
import * as API from '../../ApiUrl';
import stblogo from '../../assets/img/icons/stblogo.svg';
import mail from '../../assets/img/icons/mail.svg';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormControl from '@mui/material/FormControl';
import { textFieldLabelProps } from '../../utils/common';
import { Routes } from '../../routes';

const queryString = require('query-string');
const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;

const validationSchema = yup.object({
  oldpassword: yup.string('Enter your old password').min(8, 'Old password must be 8 character').required('Old Password is required'),

  password: yup
    .string('Enter your password')
    .matches(passwordRegex, 'Please enter at least 8 characters, 1 lowercase, 1 uppercase, 1  special character & 1 number')
    .min(8, 'Password should be of minimum 8 characters length')
    .required('New Password is required'),

  confirmpassword: yup
    .string('Enter your password')
    .oneOf([yup.ref('password')], 'Passwords do not match')
    .required('Confirm Password is Required'),
});

export default () => {
  const history = useHistory();
  const parsed = queryString.parse(history.location.search);

  const [error, setError] = useState(null);
  const [loading, setLoading] = React.useState(false);
  const [isButtonLoading, setIsButtonLoading] = React.useState(false);
  const [showTempPassword, setShowTempPassword] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);

  const dispatch = useDispatch();

  const onSubmit = async (values) => {
    setLoading(true);
    const data = {
      old_password: values.oldpassword,
      new_password: values.password,
      token: parsed.token,
    };
    try {
      const response = await axios.post(API.RESET_PASSWORD, data);
      toast.success('Congratulations, your password has been successfully updated');
      localStorage.clear();
      dispatch({ type: actionTypes.CLEAR_STORE_DATA });
      history.push(Routes.Signin.path);
    } catch (err) {
      toast.error(err?.data?.message || 'Something went wrong');
      setError(err?.data?.message);
    }
    setLoading(false);
  };
  const formik = useFormik({
    initialValues: {
      oldpassword: parsed.tmp_pwd,
      password: '',
      confirmpassword: '',
    },
    validateOnBlur: true,
    // validateOnChange: false,
    onSubmit,
    validationSchema: validationSchema,
    // isInitialValid: false,
    validateOnMount: true,
  });
  return (
    <main>
      <section className='primary_color vh-lg-100 position-relative'>
        <img src={stblogo} className='position-absolute top-5 start-50 translate-middle' alt='logo' />

        <a className='socials twitter' href='https://twitter.com/Spend_The_Bits' target='_blank' rel='noopener noreferrer'>
          @Spend_The_Bits
        </a>
        <a className='socials mail' href='mailto:help@spendthebits.com' target='_blank' rel='noopener noreferrer'>
          <img src={mail} alt='mail' />
          help@spendthebits.com
        </a>
        <Container>
          <Dialog
            open={true}
            disableEscapeKeyDown={true}
            fullWidth
            className='wallet-configure'
            BackdropProps={{ style: { backgroundColor: 'rgba(11, 5, 35, 0)' } }}
            PaperProps={{
              style: {
                boxShadow: 'none',
                borderRadius: '8px',
                width: '480px',
                minHeight: '428px',
                maxHeight: '80vh',
              },
            }}
          >
            <DialogTitle className='text-center'>
              <p className='dialog-title fw-bold'>Change User Password </p>
            </DialogTitle>
            <DialogContent>
              <Form onSubmit={formik.handleSubmit}>
                <FormControl sx={{ m: 1 }} variant='outlined' className='w-100 pb-0 mb-4'>
                  <TextField
                    variant='outlined'
                    className='w-100 mt-3'
                    id='oldpassword'
                    type={showTempPassword ? 'text' : 'password'}
                    label='Temporary Password'
                    value={formik.values.oldpassword}
                    onChange={formik.handleChange}
                    InputLabelProps={textFieldLabelProps}
                    onBlur={formik.handleBlur}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={() => {
                              setShowTempPassword((prevState) => !prevState);
                            }}
                            edge='end'
                          >
                            {showTempPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>

                <FormControl sx={{ m: 1 }} variant='outlined' className='w-100 pb-0 mb-4'>
                  <TextField
                    // onChange={(e) => handleChange(e)}
                    variant='outlined'
                    className='w-100'
                    id='password'
                    type={showPassword ? 'text' : 'password'}
                    label='Password'
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    InputLabelProps={textFieldLabelProps}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={() => {
                              setShowPassword((prevState) => !prevState);
                            }}
                            edge='end'
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={formik.touched.password && Boolean(formik.errors.password)}
                    helperText={formik.touched.password && formik.errors.password}
                  />
                </FormControl>

                <FormControl sx={{ m: 1 }} variant='outlined' className='w-100 pb-0 mb-2'>
                  <TextField
                    // onChange={(e) => handleChange(e)}
                    variant='outlined'
                    className='w-100 mb-3'
                    id='confirmpassword'
                    label='Confirm Password'
                    type={showRepeatPassword ? 'text' : 'password'}
                    value={formik.values.confirmpassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    InputLabelProps={textFieldLabelProps}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={() => {
                              setShowRepeatPassword((prevState) => !prevState);
                            }}
                            edge='end'
                          >
                            {showRepeatPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={formik.touched.confirmpassword && Boolean(formik.errors.confirmpassword)}
                    helperText={formik.touched.confirmpassword && formik.errors.confirmpassword}
                  />
                </FormControl>

                {error && (
                  <div className=' mb-2  text-danger'>
                    <span className='fw-normal'>{error}</span>
                  </div>
                )}

                <Button
                  variant='primary'
                  onClick={() => {
                    setIsButtonLoading(true);
                    setTimeout(() => {
                      setIsButtonLoading(false);
                    }, 2000);
                  }}
                  isLoading={isButtonLoading}
                  disabled={!formik.isValid}
                  className='mt-4 w-100'
                  type='submit'
                >
                  {loading && <CircularProgress size={14} />}
                  {!loading && 'Change Password'}
                </Button>

                <ToastContainer />
              </Form>
            </DialogContent>
          </Dialog>
        </Container>
      </section>
    </main>
  );
};
