import React from "react";
import Signin from "./Signin";

const Authentication = () => {
  return (
    <>
      <Signin />
    </>
  );
};

export default Authentication;
