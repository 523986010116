import React from "react";
import {
  Page,
  Document,
  StyleSheet,
  View,
  PDFDownloadLink,
  PDFViewer,
  Image,
} from "@react-pdf/renderer";
import { Button } from "rsuite";
import Iconify from "../Iconify";
import RemittanceSection from "./RemittanceSection";
import SenderSection from "./SenderSection";
import RecipientSection from "./RecipientSection";
import ButtonSecondary from "../ButtonSecondary";
import InvestVertex from "../../assets/img/icons/InvestvertexWhite.png";

const PreviewPDF = ({ coins, transaction, isInward }) => {
  const styles = StyleSheet.create({
    page: {
      // flexDirection: 'column',
      marginBottom: "24px",
      // backgroundColor: '#E4E4E4',
      padding: "8px",
    },
    image: {
      width: 200,
      height: 300,
      // marginVertical: 15,
      // marginHorizontal: 100,
    },
    section: {
      margin: 10,
      padding: 10,
      // flexGrow: 1,
    },
    header: {
      backgroundColor: "#0b0523",
      borderRadius: 8,
      padding: 8,
      marginBottom: 10,
      // height:216,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  });
  const MyDocument = () => (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Image src={InvestVertex} style={styles.image} />
        </View>
        <RemittanceSection
          coins={coins}
          transaction={transaction}
          isInward={isInward}
        />
        <SenderSection type={transaction.type} sender={transaction.sender} />
        {/* </Page>
      <Page size="TABLOID" style={styles.page}> */}
        <RecipientSection
          type={transaction.type}
          receiver={transaction.receiver}
          receiver_account={transaction.receiver_account}
        />
      </Page>
    </Document>
  );

  return (
    <>
      {/* <PDFViewer>
        <MyDocument />
      </PDFViewer> */}
      <PDFDownloadLink
        document={<MyDocument />}
        fileName={`${transaction.reference_number}.pdf`}
      >
        <ButtonSecondary>
          <Iconify sx={{ mr: 1 }} icon={"eva:download-outline"} />
          Download
        </ButtonSecondary>
      </PDFDownloadLink>
    </>
  );
};

export default PreviewPDF;
