import React from "react";
import { styled } from "@material-ui/core/styles";
import { Typography, Stack, Box, Paper } from "@mui/material";

const MyPaper = styled(Paper)({
  borderRadius: 8,
  borderColor: "#000",
  backgroundColor: "#F0F0F0",
  padding: "1rem",
});

const PreviewContainer = ({ children, title, sx }) => {
  return (
    <MyPaper sx={{ flex: "1", ...sx }} elevation={0}>
      <Stack direction={"column"} spacing={2} sx={{ flex: 1 }}>
        <Typography variant={"h6"}>{title}</Typography>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(200px, 200px))",
            gridGap: "1rem",
          }}
        >
          {children}
        </Box>
      </Stack>
    </MyPaper>
  );
};

export default PreviewContainer;
