import React, { useEffect, useState } from "react";
import { styled } from "@material-ui/core/styles";
import {
  Typography,
  Stack,
  Box,
  Paper,
  Tooltip,
  IconButton,
} from "@mui/material";
import { AddNewCard } from "../../../../../components/addNewCard";
import { DocumentCard } from "../../../../../components/documentCard";
import UploadKybDocument from "../../../../../components/uploadDocumentModal/UploadKybDocument";
import { Routes } from "../../../../../routes";
import { useHistory } from "react-router-dom";
import { Iconify } from "../../../../../components";

const MyPaper = styled(Paper)({
  borderRadius: 8,
  borderColor: "#000",
  backgroundColor: "#F0F0F0",
  padding: "1rem",
});
const DocumentDetails = ({ client, getClient }) => {
  const [showDocumentUpload, setShowDocumentUpload] = useState(false);
  const [shareholderDoc, setShareholderDoc] = useState([]);

  const { sender_document, kyb_status } = client;

  console.log(client, "cliecacdewcecwecwecasnt");

  const toggleDocumentUpload = () => {
    setShowDocumentUpload((prevState) => !prevState);
  };

  useEffect(() => {
    setShareholderDoc([
      {
        id: 1,
        document: client?.shareholder_document?.back_part_of_document,
        document_type: { name: "Back part of document" },
      },
      {
        id: 2,
        document: client?.shareholder_document?.front_part_of_document,
        document_type: { name: "Front part of document" },
      },
    ]);
  }, []);
  const history = useHistory();

  // related_kyc_support_ticket

  return (
    <>
      <Box>
        <MyPaper sx={{ flex: "1" }} elevation={0}>
          <Stack direction={"column"} spacing={2} sx={{ flex: 1 }}>
            <Stack direction={"row"}>
              <Typography mr={2} variant={"h6"}>
                Business Documents
              </Typography>
              <Tooltip title={"Go to KYC ticket"} sx={{ mr: 2 }}>
                <IconButton
                  disabled={!client?.related_kyc_support_ticket}
                  onClick={() => {
                    history.push(
                      `${Routes.UserChatGeneral.path}ticket/${client?.related_kyc_support_ticket}`
                    );
                  }}
                >
                  <Iconify
                    icon="material-symbols:open-in-new-rounded"
                    width={20}
                    height={20}
                  />
                </IconButton>
              </Tooltip>
            </Stack>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit, minmax(200px, 200px))",
                gridGap: "1rem",
                width: "100%",
              }}
            >
              <>
                {sender_document?.map((document, i) => {
                  return (
                    <DocumentCard
                      documents={document}
                      docName={document?.document_type?.name}
                      // canDelete={true}
                      // onDocumentDelete={getClient}
                    />
                  );
                })}
              </>
              <AddNewCard onClick={toggleDocumentUpload} />
            </Box>
          </Stack>
          <Stack direction={"column"} spacing={2} sx={{ flex: 1, mt: "30px" }}>
            <Typography variant={"h6"}>Personal Documents</Typography>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: 2,
              }}
            >
              {/* <>
                {shareholderDoc?.map((document, i) => {
                  return document?.document ? (
                    <DocumentCard
                      documents={document}
                      docName={document?.document_type?.name}
                      // canDelete={true}
                      // onDocumentDelete={getClient}
                    />
                  ) : (
                    ""
                  );
                })}
              </> */}
              {client?.shareholder_document?.documents?.map((data, i) => {
                const documentParts = [
                  {
                    name: "Front Side",
                    document: data?.front_part_document,
                    document_type: data?.document_type,
                  },
                  {
                    name: "Back Side",
                    document: data?.back_part_document
                      ? data?.back_part_document
                      : null,
                    document_type: data?.document_type,
                  },
                ];

                return (
                  <Box key={i}>
                    <Typography sx={{ fontWeight: 600, mb: 1 }}>
                      {data?.metadata === "id_proof"
                        ? "ID Proof"
                        : data?.metadata === "address_proof"
                        ? "Address Proof"
                        : "Other Documents"}
                    </Typography>
                    <Typography sx={{ fontWeight: 500, mb: 1 }}>
                      {data?.document_type?.name}
                    </Typography>
                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        gap: 1,
                      }}
                    >
                      {documentParts.map((doc, index) => {
                        if (doc?.document) {
                          return (
                            <Box
                              // onClick={() =>
                              //   handleOpenPersonalDocLightbox(
                              //     index,
                              //     documentParts
                              //   )
                              // }
                              key={doc.name}
                              sx={{ pt: 1, textAlign: "center" }}
                            >
                              <DocumentCard
                                documents={{
                                  document: doc?.document,
                                  name: doc?.name,
                                  document_type: doc?.document_type,
                                }}
                                canDelete={false}
                              />
                            </Box>
                          );
                        }
                      })}
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Stack>
          {client.shareholders.length > 1 && (
            <Stack
              direction={"column"}
              spacing={2}
              sx={{ flex: 1, mt: "30px" }}
            >
              <Typography variant={"h6"}>Shareholder Documents</Typography>
              {client.shareholders
                .filter(
                  (shareholder) => shareholder.email !== client.user.email
                )
                .map((shareholder) => (
                  <>
                    <Typography
                      variant={"subtitle2"}
                    >{`${shareholder.first_name} ${shareholder.last_name}`}</Typography>
                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns:
                          "repeat(auto-fit, minmax(200px, 200px))",
                        gridGap: "1rem",
                        width: "100%",
                      }}
                    >
                      <>
                        {shareholder?.documents?.map((document, i) => {
                          return document?.document ? (
                            <DocumentCard
                              documents={document}
                              docName={document?.document_type?.name}
                              // canDelete={true}
                              // onDocumentDelete={getClient}
                            />
                          ) : (
                            ""
                          );
                        })}
                      </>
                    </Box>
                  </>
                ))}
            </Stack>
          )}
          {/* <Stack direction={"column"} spacing={2} sx={{ flex: 1, mt: "30px" }}>
            <Typography variant={"h6"}>Additional Documents</Typography>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit, minmax(200px, 200px))",
                gridGap: "1rem",
                width: "100%",
              }}
            >
              <>
                <AddNewCard onClick={toggleDocumentUpload} />
              </>
            </Box>
          </Stack> */}
        </MyPaper>
      </Box>
      {showDocumentUpload && (
        <UploadKybDocument
          isOpen={showDocumentUpload}
          onClose={toggleDocumentUpload}
          senderId={client.id}
          getSenderAccounts={getClient}
          kybStatus={kyb_status}
        />
      )}
    </>
  );
};

export default DocumentDetails;
