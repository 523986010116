import React from "react";
import { format } from "date-fns";
import { startCase, toLower } from "lodash";
import {
  Box,
  Stack,
  Typography,
  LinearProgress,
  Avatar,
  Tooltip,
  IconButton,
} from "@mui/material";

import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import {
  rowsPerPageOptions,
  transactionStatusColors,
} from "../../../../utils/common";

import { DataGrid } from "@mui/x-data-grid";
import { formatCurrency, tableCellStyle } from "../../../../utils/common";
import createAvatar from "../../../../utils/createAvatar";
import { Label } from "../../../../components";

export default function AvailableRedemptionReportTable({
  translist,
  paginateTable,
  setPaginateTable,
  fetchTranslistLoading,
}) {
  console.log(translist);
  const handleChangeRowsPerPage = (newSize) => {
    setPaginateTable({
      ...paginateTable,
      currentPage: 0,
      pageSize: newSize,
    });
  };

  const createDataRow = (transaction) => {
    return {
      id: transaction?.mto?.id,
      // type: capital(transaction?.type) || 'P2P',
      mto: transaction?.mto?.name,
      pending_amount: (() => {
        return formatCurrency(
          Number(transaction?.amount),
          transaction?.coin?.decimal_place,
          transaction?.coin?.symbol,
          transaction?.coin?.symbol_prefix
        );
      })(),

      // currency: transaction.coin.symbol,

      associated_remittance_ids: transaction.remittances,
    };
  };

  const rows =
    translist?.length > 0
      ? translist.map((transaction) => createDataRow(transaction))
      : [];

  const columns = [
    {
      field: "id",
      headerName: "Reference Number",
      flex: 1,
      minWidth: 150,
      // maxWidth: 200,
      headerClassName: "header",
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => {
        return (
          <Tooltip
            placement="bottom"
            sx={{ cursor: "default" }}
            title={params.formattedValue}
          >
            <Typography variant="body2" style={tableCellStyle}>
              {params.formattedValue}
            </Typography>
          </Tooltip>
        );
      },
    },

    {
      field: "mto",
      headerName: "MTO",
      flex: 1,
      minWidth: 175,
      // maxWidth: 200,
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <Stack direction={"row"} sx={{ minWidth: 0, alignItems: "center" }}>
            <Avatar
              alt={params.formattedValue}
              sx={{ mr: 1, bgcolor: createAvatar(params.formattedValue).color }}
            >
              {createAvatar(params.formattedValue).name}
            </Avatar>
            <Typography
              variant="body2"
              sx={{
                color: "black",
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontWeight: "500",
              }}
            >
              {params.formattedValue}
            </Typography>
          </Stack>
        );
      },
    },

    {
      field: "pending_amount",
      headerName: "Pending Amount",
      flex: 1.5,
      minWidth: 200,
      // maxWidth: 200,
      headerClassName: "header",
      sortable: false,
      renderCell: (params) => {
        return (
          <Typography variant="body2" style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
    },

    // {
    //   field: 'currency',
    //   headerName: 'Currency',
    //   flex: 1.5,
    //   minWidth: 200,
    //   // maxWidth: 200,
    //   textOverflow: 'ellipsis',
    //   headerClassName: 'header',
    //   sortable: false,
    //   renderCell: (params) => {
    //     return (
    //       <Typography variant="body2" style={tableCellStyle}>
    //         {params.formattedValue}
    //       </Typography>
    //     );
    //   },
    // },

    {
      field: "associated_remittance_ids",
      headerName: "Associated Operation ID's",
      flex: 1.5,
      minWidth: 250,
      align: "center",
      // maxWidth: 200,
      headerClassName: "header",
      sortable: false,
      renderCell: (params) => {
        return (
          <Tooltip placement="top" title={params.formattedValue}>
            <span>
              <IconButton>
                <InfoRoundedIcon />
              </IconButton>
            </span>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <>
      <Box
        sx={{
          // height: 825,
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 0px 8px 0px",
          borderRadius: "8px",
          width: "100%",
          "& .MuiDataGrid-virtualScrollerRenderZone": {
            "& .MuiDataGrid-row": {
              // backgroundColor: 'rgba(235, 235, 235, .7)',
              fontWeight: 500,
              color: "#667085",
              fontFamily: "Inter, sans-serif",
              fontSize: "14px",
            },
          },
          "& .header": {
            backgroundColor: "#F4F4F4",
            "& div": {
              "& div": {
                "& div": {
                  "& div": {
                    fontSize: "14px",
                    fontWeight: 600,
                    fontFamily: "Inter, sans-serif",
                  },
                },
              },
            },
          },
        }}
      >
        <DataGrid
          autoHeight
          sx={{
            borderRadius: "8px",
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 0px 8px 0px",
            width: "100%",
          }}
          components={{
            LoadingOverlay: LinearProgress,
          }}
          rows={rows}
          columns={columns}
          rowHeight={50}
          disableColumnFilter={true}
          disableColumnMenu={true}
          pagination
          rowsPerPageOptions={rowsPerPageOptions}
          rowCount={translist?.length}
          pageSize={paginateTable.pageSize}
          onPageSizeChange={(newSize) => handleChangeRowsPerPage(newSize)}
          loading={fetchTranslistLoading}
        />
      </Box>
    </>
  );
}
