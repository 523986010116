import React, { useState, useEffect, useCallback } from "react";
import { merchantApi } from "../../../redux/api/apiConfig";
import * as API from "../../../ApiUrl";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "rsuite/dist/styles/rsuite-default.css";
import { FilterDropdown } from "../components";
import {
  Box,
  Stack,
  MenuItem,
  Autocomplete,
  TextField,
  Card,
  Divider,
} from "@mui/material";
import { reportTimePeriods, timePeriodOptions } from "../common";
import {
  ButtonPrimary,
  ButtonSecondary,
  ButtonResetFilter,
  Iconify,
} from "../../../components";
import { format as Format } from "date-fns";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import OutwardRemittanceTable from "./outwardRemittanceTable";
import { Highlight } from "../components";
import { StyledDatePicker } from "../../../components/datePicker";
import usePageTitle from "../../../hooks/usePageTitle";
import { HighlightSkeleton } from "../components";
import { formatDate } from "../../../utils/common";
import {
  UPDATE_OUT_REM_REPORT,
  CLEAR_OUT_REM_REPORT,
} from "../../../redux/actionTypes";

function OutwardRemittanceReport() {
  const [mtoOptions, setMtoOptions] = useState([]);
  const [accountOptions, setAccountOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [highlightLoading, setHighlightLoading] = useState(false);

  const [translist, setTranslist] = useState({ count: 0 });
  const [highlights, setHighlights] = useState([]);

  console.log(reportTimePeriods, new Date(reportTimePeriods[1].to));

  usePageTitle("Outward Operation Report", "Outward Operation Analytics");
  const filters = useSelector((state) => state.filters.reports.outRemReport);
  const { configReducer } = useSelector((state) => state);
  const { remittanceStatuses = [] } = configReducer;
  const dispatch = useDispatch();

  const getMtoOptions = useCallback(async () => {
    try {
      const response = await merchantApi.get(`${API.MTO}`);
      console.log(response.data);
      setMtoOptions(response.data);
    } catch (error) {
      toast.error(error?.data?.data?.message || "Something went wrong");
      // toast.error('Could not fetch MTO list.');
    }
  }, []);

  const getAccounts = useCallback(async () => {
    try {
      const response = await merchantApi.get(`${API.MTO_ACCOUNTS}`, {
        params: {
          limit: 7,
        },
      });

      if (response.data) {
        setAccountOptions(response.data.results);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.data?.data?.message || "Something went wrong");
    }
  }, []);

  const getReport = useCallback(async () => {
    setLoading(true);
    const {
      mto,
      status,
      format,
      calenderValue,
      pageSize,
      currentPage,
      sortModel,
      mtoAccount,
    } = filters;

    let finalStatusIds = "";
    if (status) {
      if (status.length > 1) {
        finalStatusIds = status?.reduce((prev, curr) => {
          return `${prev.id},${curr.id}`;
        });
      } else if (status.length === 1) {
        finalStatusIds = status[0].id;
      }
    }
    const created_date_before = formatDate(calenderValue[1], "yyyy-MM-dd");
    const created_date_after = formatDate(calenderValue[0], "yyyy-MM-dd");

    let mtoIds = "";
    if (mto) {
      if (mto.length > 1) {
        mtoIds = mto?.reduce((prev, curr) => {
          return `${prev.id},${curr.id}`;
        });
      } else if (mto.length === 1) {
        mtoIds = mto[0].id;
      }
    }

    let ordering = "";
    console.log(sortModel);

    if (sortModel.length === 1) {
      const { field, sort } = sortModel[0];
      ordering = sort === "desc" ? `-${field}` : field;
    }

    const params = {
      status: finalStatusIds,
      mto: mtoIds,
      created_date_after,
      created_date_before,
      flow_type: "outward",
      file_format: format,
      limit: pageSize,
      offset: pageSize * currentPage,
      ordering,
      sender_mto_account: mtoAccount?.id,
    };
    try {
      const response = await merchantApi.get(API.REMITTANCE_REPORT, {
        params,
      });
      setTranslist(response.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      toast.error(err?.data?.data?.message || "Something went wrong");
    }
  }, [filters]);

  const getHighlights = useCallback(async () => {
    setHighlightLoading(true);
    try {
      const { mto, status, format, calenderValue, pageSize, currentPage } =
        filters;

      let finalStatusIds = "";
      if (status) {
        if (status.length > 1) {
          finalStatusIds = status?.reduce((prev, curr) => {
            return `${prev.id},${curr.id}`;
          });
        } else if (status.length === 1) {
          finalStatusIds = status[0].id;
        }
      }
      const created_date_before = formatDate(calenderValue[1], "yyyy-MM-dd");
      const created_date_after = formatDate(calenderValue[0], "yyyy-MM-dd");

      let mtoIds = "";
      if (mto) {
        if (mto.length > 1) {
          mtoIds = mto?.reduce((prev, curr) => {
            return `${prev.id},${curr.id}`;
          });
        } else if (mto.length === 1) {
          mtoIds = mto[0].id;
        }
      }
      const params = {
        status: finalStatusIds,
        mto: mtoIds,
        created_date_after,
        created_date_before,
        flow_type: "outward",
        file_format: format,
        limit: pageSize,
        offset: pageSize * currentPage,
      };
      const response = await merchantApi.get(API.REMITTANCE_HIGHLIGHT, {
        params,
      });
      console.log(response);
      setHighlights(response.data.data);
      setHighlightLoading(false);
    } catch (error) {
      console.log(error);
      toast.error(error?.data?.data?.message || "Something went wrong");
      setHighlightLoading(false);
    }
  }, [filters]);

  useEffect(() => {
    getMtoOptions();
    getAccounts();
  }, [getMtoOptions, getAccounts]);

  useEffect(() => {
    getReport();
    getHighlights();
  }, [
    filters.pageSize,
    filters.currentPage,
    filters.sortModel,
    // getReport,
    // getHighlights,
  ]);

  const resetFilters = () => {
    dispatch({ type: CLEAR_OUT_REM_REPORT });
  };

  const download = (type) => {
    if (type === "excel" && translist?.file) {
      window.open(translist?.file, "_blank");
    }
    if (type === "pdf" && translist?.doc) {
      window.open(translist?.doc, "_blank");
    }
  };
  return (
    <>
      <Box sx={{ padding: "0 24px 24px" }}>
        <Card sx={{ width: "100%" }}>
          <Stack
            direction="row"
            divider={
              <Divider
                orientation="vertical"
                flexItem
                sx={{ borderStyle: "dashed" }}
              />
            }
            sx={{ py: 2 }}
          >
            {highlightLoading
              ? [...Array(2)].map((a) => <HighlightSkeleton />)
              : highlights.map((highlight, i) => {
                  return (
                    <Highlight
                      key={highlight.heading}
                      title={highlight.heading}
                      subTitle={highlight.sub_heading}
                      // total={translist.count}
                      percent={100}
                      value={highlight.data}
                      icon={highlight?.meta?.icon}
                      color={highlight?.meta?.color}
                    />
                  );
                })}
          </Stack>
        </Card>

        <Stack sx={{ width: "100%", gap: 1, mb: 2 }}>
          <Stack
            sx={{
              overflowX: { xs: "auto" },
              overflowY: { xs: "visible", md: "unset" },
              padding: "5px 0",
            }}
            direction={"row"}
            justifyContent={"space-between"}
            gap={1}
            mt={2}
            width={"100%"}
          >
            <Stack
              sx={{ flexDirection: "row", gap: 1 }}
              divider={
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ borderStyle: "dashed" }}
                />
              }
            >
              <Stack sx={{ maxWidth: "150px", flex: 1 }}>
                <FilterDropdown
                  name="timeperiod"
                  type="text"
                  label="Timeperiod"
                  options={reportTimePeriods}
                  onChange={(e) => {
                    const name = e.target.value;
                    const period = reportTimePeriods.find(
                      (report) => report.name === name
                    );

                    let toValue;
                    if (filters.calenderValue[1]) {
                      toValue =
                        new Date(period.from) >
                        new Date(filters.calenderValue[1])
                          ? null
                          : filters.calenderValue[1];
                    } else {
                      toValue = null;
                    }

                    dispatch({
                      type: UPDATE_OUT_REM_REPORT,
                      payload: {
                        calenderValue: [new Date(period.from), toValue],
                        period,
                      },
                    });
                  }}
                  value={filters?.period?.name}
                  size={"small"}
                  render={(period, i) => (
                    <MenuItem key={period.name} value={period.name}>
                      {period.name}
                    </MenuItem>
                  )}
                />
              </Stack>
              <Stack sx={{ flexDirection: "row", gap: 1 }}>
                <Stack sx={{ width: "176px", flex: 1 }}>
                  <StyledDatePicker
                    value={filters.calenderValue[0]}
                    disableFuture
                    format={"dd/MM/yyyy"}
                    onChange={(newValue) => {
                      dispatch({
                        type: UPDATE_OUT_REM_REPORT,
                        payload: {
                          calenderValue: [
                            new Date(Format(newValue, "yyyy/MM/dd")),
                            newValue > filters.calenderValue[1]
                              ? undefined
                              : filters.calenderValue[1],
                          ],
                        },
                      });
                      // setRemittanceReport((prevState) => ({
                      //   ...prevState,
                      //   calenderValue: [
                      //     new Date(Format(newValue, 'yyyy/MM/dd')),
                      //     newValue > filters.calenderValue[1]
                      //       ? undefined
                      //       : filters.calenderValue[1],
                      //   ],
                      // }));
                    }}
                  />
                </Stack>

                <Stack sx={{ width: "176px", flex: 1 }}>
                  <StyledDatePicker
                    value={filters.calenderValue[1]}
                    disableFuture
                    // minDate={filters.calenderValue[0] ?? undefined}
                    format={"dd/MM/yyyy"}
                    onChange={(newValue) => {
                      dispatch({
                        type: UPDATE_OUT_REM_REPORT,
                        payload: {
                          calenderValue: [
                            newValue < filters.calenderValue[0]
                              ? undefined
                              : filters.calenderValue[0],
                            new Date(Format(newValue, "yyyy/MM/dd")),
                          ],
                        },
                      });
                      // setRemittanceReport((prevState) => ({
                      //   ...prevState,
                      //   calenderValue: [
                      //     newValue < filters.calenderValue[0]
                      //       ? undefined
                      //       : filters.calenderValue[0],
                      //     new Date(Format(newValue, 'yyyy/MM/dd')),
                      //   ],
                      // }));
                    }}
                  />
                </Stack>
              </Stack>
            </Stack>
            <Stack sx={{ flexDirection: "row", gap: 1 }}>
              <ButtonResetFilter onClick={resetFilters} />

              <ButtonSecondary onClick={() => download("excel")}>
                <Iconify
                  icon={"vscode-icons:file-type-excel"}
                  sx={{ width: "20px" }}
                />
              </ButtonSecondary>

              <ButtonPrimary
                onClick={() => {
                  getReport();
                  getHighlights();
                }}
                loading={loading}
              >
                Fetch Report
              </ButtonPrimary>
            </Stack>
          </Stack>
          <Accordion sx={{ width: "100%" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography sx={{ width: "33%", flexShrink: 0 }}>
                More Filters
              </Typography>
              <Typography sx={{ color: "text.secondary" }}>
                More filters to view specific data analytics
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Stack sx={{ flexDirection: "row", gap: 1, flexWrap: "wrap" }}>
                <Stack sx={{ flexGrow: 1, minWidth: "250px", maxWidth: "50%" }}>
                  <Autocomplete
                    multiple={true}
                    limitTags={2}
                    id="mto"
                    value={filters.mto}
                    onChange={(e, newValue) => {
                      console.log(newValue);
                      dispatch({
                        type: UPDATE_OUT_REM_REPORT,
                        payload: {
                          mto: newValue ?? null,
                        },
                      });
                      // setRemittanceReport((prevState) => ({
                      //   ...prevState,
                      //   mto: newValue ?? null,
                      // }));
                    }}
                    options={mtoOptions}
                    getOptionLabel={(option) =>
                      `${option?.name} (${option?.country?.name})`
                    }
                    sx={{ flexGrow: 1, width: "100%" }}
                    renderInput={(params) => {
                      return (
                        <TextField
                          placeholder="Select MTO"
                          {...params}
                          size="small"
                        />
                      );
                    }}
                  />
                </Stack>
                <Stack sx={{ flexGrow: 1, minWidth: "250px", maxWidth: "50%" }}>
                  <Autocomplete
                    id="mtoAccount"
                    value={filters.mtoAccount}
                    onChange={(e, newValue) => {
                      console.log(newValue);
                      dispatch({
                        type: UPDATE_OUT_REM_REPORT,
                        payload: {
                          mtoAccount: newValue ?? null,
                        },
                      });
                    }}
                    options={accountOptions}
                    getOptionLabel={(option) => `${option?.name}`}
                    sx={{ flexGrow: 1, width: "100%" }}
                    renderInput={(params) => {
                      return (
                        <TextField
                          placeholder="Select MTO account"
                          {...params}
                          size="small"
                        />
                      );
                    }}
                  />
                </Stack>
                <Stack sx={{ flexGrow: 1, minWidth: "250px", maxWidth: "50%" }}>
                  <Autocomplete
                    multiple={true}
                    limitTags={2}
                    id="status"
                    value={filters.status}
                    onChange={(e, newValue) => {
                      console.log(newValue);
                      dispatch({
                        type: UPDATE_OUT_REM_REPORT,
                        payload: {
                          status: newValue ?? null,
                        },
                      });
                    }}
                    options={remittanceStatuses}
                    getOptionLabel={(option) => option?.sender_name}
                    sx={{ flexGrow: 1, width: "100%" }}
                    renderInput={(params) => {
                      return (
                        <TextField
                          placeholder="Select Status"
                          {...params}
                          size="small"
                        />
                      );
                    }}
                  />
                </Stack>
              </Stack>
            </AccordionDetails>
          </Accordion>
        </Stack>
        <OutwardRemittanceTable
          translist={translist}
          fetchTranslistLoading={loading}
        />
      </Box>
    </>
  );
}

export default OutwardRemittanceReport;
