import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { toast } from "react-toastify";
import "rsuite/dist/styles/rsuite-default.css";

import * as API from "../../../ApiUrl";
import { RemittanceTable } from "./components";
import { merchantApi } from "../../../redux/api/apiConfig";

import StatusModal from "./components/statusModal/StatusModal";
import PassphraseModal from "./components/passphraseModal";
import { send } from "../../../utils/xrpl_functions";
import { format } from "date-fns";
import {
  Box,
  Divider,
  MenuItem,
  Stack,
  TextField,
  InputAdornment,
  Autocomplete,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { Routes } from "../../../routes";
import usePageTitle from "../../../hooks/usePageTitle";
import { reportTimePeriods } from "../../reports/common";
import { formatDate } from "../../../utils/common";
import { ButtonResetFilter, Iconify } from "../../../components";
import { StyledDatePicker } from "../../../components/datePicker";
import { FilterDropdown } from "../../reports/components";
import {
  CLEAR_INWARD_REMITTANCE,
  UPDATE_INWARD_REMITTANCE,
} from "../../../redux/actionTypes";

function InwardRemittance() {
  const [translist, setTranslist] = useState([]);
  const [fetchTranslistLoading, setFetchTranslistLoading] = useState(false);
  const [transactionData, setTransactionData] = useState("");

  const [showStatusModal, setShowStatusModal] = useState(false);
  const [mtoPatchLoading, setMtoPatchLoading] = useState(false);
  const [counts, setCounts] = useState({
    pending: 0,
    processed: 0,
    all: 0,
  });
  const { configReducer } = useSelector((state) => state);
  const filters = useSelector((state) => state.filters.remittances.inward);
  // const { coins = [] } = walletsReducer;
  const { remittanceStatuses: allStatuses = [] } = configReducer;

  const dispatch = useDispatch();
  const history = useHistory();
  usePageTitle("Inward Operation", "View your incoming operation requests");

  const handleClearFilters = () => {
    dispatch({ type: CLEAR_INWARD_REMITTANCE });
  };

  const getCounts = useCallback(async (filters) => {
    console.log(filters);
    const created_date_before = formatDate(
      filters?.calenderValue[1],
      "yyyy-MM-dd"
    );
    const created_date_after = formatDate(
      filters?.calenderValue[0],
      "yyyy-MM-dd"
    );
    merchantApi(`${API.REMITTANCE_INCOMING}`, {
      params: {
        limit: 1,
        created_date_before,
        created_date_after,
      },
    })
      .then((response) => {
        const { count, pending, complete } = response.data;
        setCounts({
          pending,
          processed: complete,
          all: count,
        });
      })
      .catch((error) => {
        console.log(error);
        toast.error(error?.data?.data?.message || "Something went wrong");
      });
  }, []);

  const getTrans = useCallback(async () => {
    setFetchTranslistLoading(true);
    const {
      calenderValue,
      pageSize,
      currentPage,
      search,
      status,
      statusValue,
    } = filters;
    const created_date_before = formatDate(calenderValue[1], "yyyy-MM-dd");
    const created_date_after = formatDate(calenderValue[0], "yyyy-MM-dd");
    let params = {
      offset: currentPage * pageSize,
      limit: pageSize,
      search,
      status: status?.id,
      created_date_after,
      created_date_before,
    };
    // If table status is all, only then the status filter value will be passed
    if (statusValue === "processed" || statusValue === "payout_done") {
      let completedId = allStatuses
        .filter(
          (status) =>
            status.sender_can_change_to.length === 0 &&
            status.receiver_can_change_to.length === 0
        )
        .map((status) => status.id);
      delete params.status;
      let finalStatusIds = completedId.reduce((prev, curr) => {
        return `${prev},${curr}`;
      });
      if (statusValue === "processed") {
        params.status_not = finalStatusIds;
      } else {
        params.status = finalStatusIds;
      }
      console.log(params, statusValue);
    }

    merchantApi
      .get(`${API.REMITTANCE_INCOMING}`, {
        params,
      })
      .then((response) => {
        setFetchTranslistLoading(false);
        if (response.data) {
          setTranslist(response.data);
        }
      })
      .catch((error) => {
        toast.error(error?.data?.data?.message || "Something went wrong");
        setFetchTranslistLoading(false);
      });
  }, [filters, allStatuses]);

  useEffect(() => {
    const timer = setTimeout(() => {
      getTrans();
    }, 500);

    return () => clearTimeout(timer);
  }, [
    filters.currentPage,
    filters.pageSize,
    filters.search,
    filters.status,
    getTrans,
  ]);

  useEffect(() => {
    const timer = setTimeout(() => {
      console.log("here");
      getCounts(filters);
    }, 500);

    return () => clearTimeout(timer);
  }, [filters.calenderValue, getCounts]);

  const viewDetailsHandler = (id) => {
    history.push(Routes.InwardRemittance.path + `/${id}`);
  };

  const toggleStatusModal = () => {
    setShowStatusModal((state) => !state);
  };

  const updateStatusClickHandler = (transaction) => {
    setTransactionData(transaction);
    toggleStatusModal();
  };

  const updateClickHandler = async (status, remarks, document) => {
    // let value = allStatuses.find((statusItem) => statusItem.id === status).value;
    // if (value === 'reject_request') {
    //   localStorage.setItem('remarks', remarks);
    //   setShowPassphraseModal(true);
    // } else {
    if (document) {
      updateStatusWithDocument(status, remarks, document);
    } else {
      updateStatus(status, remarks);
    }
    // }
  };

  const updateStatusWithDocument = async (status, remarks, documents) => {
    try {
      setMtoPatchLoading(true);
      let fileData = new FormData();
      fileData.append("documents", documents);
      fileData.append("remarks", remarks);
      fileData.append("status", status);

      const response = await merchantApi.patch(
        `${API.REMITTANCE_UPLOAD}${transactionData.id}/`,
        fileData
      );
      setMtoPatchLoading(false);
      toggleStatusModal();
      getTrans();
      getCounts(filters);
    } catch (error) {
      setMtoPatchLoading(false);
      console.log(error, "aojcbiasjdbcksadbcsd");
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

  const updateStatus = async (status, remarks) => {
    try {
      setMtoPatchLoading(true);
      const response = await merchantApi.patch(
        `${API.REMITTANCE_INCOMING}${transactionData.id}/`,
        {
          status,
          remarks,
        }
      );
      setMtoPatchLoading(false);
      toggleStatusModal();
      getTrans();
      getCounts(filters);
    } catch (error) {
      setMtoPatchLoading(false);
      toast.error(error?.data?.data?.message || "Something went wrong");
    }
  };

  return (
    <>
      <Box sx={{ padding: "0 24px 24px" }}>
        <Stack
          sx={{
            overflowX: { xs: "auto" },
            overflowY: { xs: "visible", md: "unset" },
            padding: "5px 0",
          }}
          direction={"row"}
          justifyContent={"space-between"}
          gap={1}
          mt={2}
          width={"100%"}
        >
          <Stack
            sx={{ flexDirection: "row", gap: 1 }}
            divider={
              <Divider
                orientation="vertical"
                flexItem
                sx={{ borderStyle: "dashed" }}
              />
            }
          >
            <Stack sx={{ minWidth: "150px", flex: 1 }}>
              <FilterDropdown
                name="timeperiod"
                type="text"
                label="Timeperiod"
                options={reportTimePeriods}
                onChange={(e) => {
                  const name = e.target.value;
                  const period = reportTimePeriods.find(
                    (report) => report.name === name
                  );

                  let toValue;
                  if (filters.calenderValue[1]) {
                    toValue =
                      new Date(period.from) > new Date(filters.calenderValue[1])
                        ? null
                        : filters.calenderValue[1];
                  } else {
                    toValue = null;
                  }
                  dispatch({
                    type: UPDATE_INWARD_REMITTANCE,
                    payload: {
                      calenderValue: [new Date(period.from), toValue],
                      period,
                    },
                  });
                }}
                value={filters?.period?.name}
                size={"small"}
                render={(period, i) => (
                  <MenuItem key={period.name} value={period.name}>
                    {period.name}
                  </MenuItem>
                )}
              />
            </Stack>
            <Stack sx={{ flexDirection: "row", gap: 1 }}>
              <Stack sx={{ width: "176px", flex: 1 }}>
                <StyledDatePicker
                  value={filters.calenderValue[0]}
                  disableFuture
                  format={"dd/MM/yyyy"}
                  onChange={(newValue) => {
                    dispatch({
                      type: UPDATE_INWARD_REMITTANCE,
                      payload: {
                        calenderValue: [
                          new Date(format(newValue, "yyyy/MM/dd")),
                          newValue > filters.calenderValue[1]
                            ? undefined
                            : filters.calenderValue[1],
                        ],
                      },
                    });
                  }}
                />
              </Stack>

              <Stack sx={{ width: "176px", flex: 1 }}>
                <StyledDatePicker
                  value={filters.calenderValue[1]}
                  disableFuture
                  // minDate={filters.calenderValue[0] ?? undefined}
                  format={"dd/MM/yyyy"}
                  onChange={(newValue) => {
                    dispatch({
                      type: UPDATE_INWARD_REMITTANCE,
                      payload: {
                        calenderValue: [
                          newValue < filters.calenderValue[0]
                            ? undefined
                            : filters.calenderValue[0],
                          new Date(format(newValue, "yyyy/MM/dd")),
                        ],
                      },
                    });
                  }}
                />
              </Stack>
            </Stack>

            <Stack sx={{ flexDirection: "row", gap: 1, flex: 1 }}>
              {/* {coins && (
                <Stack sx={{ minWidth: '205px', flex: 1 }}>
                  <Autocomplete
                    id='currency'
                    value={filters.coin}
                    onChange={(e, newValue) => {
                      dispatch({
                        type: UPDATE_INWARD_REMITTANCE,
                        payload: {
                          currentPage: 0,
                          coin: newValue,
                        },
                      });

                      // handleCoinchange(newValue.symbol);
                    }}
                    options={coins}
                    getOptionLabel={(option) => `${option?.name}`}
                    renderInput={(params) => {
                      return <TextField placeholder='Select Currency' {...params} size='small' />;
                    }}
                  />
                </Stack>
              )} */}
              <Stack sx={{ minWidth: "200px", flex: 1 }}>
                <TextField
                  fullWidth
                  size="small"
                  value={filters.search}
                  onChange={(e) =>
                    dispatch({
                      type: UPDATE_INWARD_REMITTANCE,
                      payload: {
                        search: e.target.value,
                      },
                    })
                  }
                  placeholder="Search..."
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Iconify
                          icon="eva:search-fill"
                          sx={{ color: "text.disabled" }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Stack>
            </Stack>

            <Stack sx={{ minWidth: "200px", flex: 1 }}>
              <Autocomplete
                id="status"
                value={filters.status}
                onChange={(e, newValue) => {
                  console.log(newValue);
                  dispatch({
                    type: UPDATE_INWARD_REMITTANCE,
                    payload: {
                      status: newValue ?? null,
                      statusValue: "",
                    },
                  });
                }}
                options={allStatuses}
                getOptionLabel={(option) => option?.sender_name}
                sx={{ flexGrow: 1, width: "100%" }}
                renderInput={(params) => {
                  return (
                    <TextField
                      placeholder="Select Status"
                      {...params}
                      size="small"
                    />
                  );
                }}
              />
            </Stack>
          </Stack>
          <Stack sx={{ flexDirection: "row", gap: 1 }}>
            <ButtonResetFilter onClick={handleClearFilters} />
          </Stack>
        </Stack>
        <RemittanceTable
          translist={translist}
          fetchTranslistLoading={fetchTranslistLoading}
          viewDetails={viewDetailsHandler}
          updateStatus={updateStatusClickHandler}
          counts={counts}
        />
      </Box>

      {showStatusModal && (
        <StatusModal
          isOpen={showStatusModal}
          onClose={toggleStatusModal}
          updateClickHandler={updateClickHandler}
          mtoPatchLoading={mtoPatchLoading}
          status={transactionData?.current_status}
          canChangeTo={transactionData?.current_status?.receiver_can_change_to}
        />
      )}
    </>
  );
}

export default InwardRemittance;
